import ButtonLink from "../components/ButtonLink";

type VideoSectionProps = {
  video: string;
  title: string;
  registerUrl?: string; // if undefined, the button will not be displayed
  image: string;
};

const VideoSection = (props: VideoSectionProps) => {
  return (
    <div className="video-section">
      <h1>{props.title}</h1>
      <div className="video-container">
        <div>
          <a
            href="https://www.youtube.com/watch?v=92u6IdkEzKo"
            target="_blank"
            rel="noreferrer"
            className="video-player"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="124"
              height="125"
              viewBox="0 0 124 125"
              fill="none"
            >
              <g filter="url(#filter0_b_2057_219)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M41.3656 14.5409C41.4273 14.5821 41.4893 14.6234 41.5515 14.6649L96.0532 50.9994C97.6302 52.0505 99.0924 53.0251 100.215 53.9308C101.387 54.8759 102.769 56.1866 103.564 58.104C104.615 60.6382 104.615 63.4865 103.564 66.0207C102.769 67.9381 101.387 69.2487 100.215 70.1939C99.0925 71.0995 97.6304 72.0741 96.0535 73.1251L41.3657 109.584C39.4381 110.869 37.7048 112.025 36.234 112.822C34.7622 113.619 32.742 114.531 30.3841 114.39C27.368 114.21 24.5819 112.719 22.759 110.309C21.334 108.425 20.9717 106.239 20.819 104.572C20.6663 102.906 20.6665 100.823 20.6666 98.506L20.6666 25.8421C20.6666 25.7674 20.6666 25.6929 20.6666 25.6187C20.6665 23.3019 20.6663 21.2188 20.819 19.5529C20.9717 17.8859 21.334 15.6993 22.759 13.8155C24.5819 11.4059 27.368 9.9148 30.3841 9.73471C32.742 9.59393 34.7622 10.5054 36.234 11.303C37.7048 12.1001 39.438 13.2557 41.3656 14.5409Z"
                  fill="white"
                  fill-opacity="0.6"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_2057_219"
                  x="-20"
                  y="-19.9375"
                  width="164"
                  height="164"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_2057_219"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_2057_219"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </a>
        </div>
        <img
          src={props.image}
          alt={props.image}
          width="100%"
          height="100%"
        />
      </div>
      {props.registerUrl && (
        <ButtonLink
            href="#"
            newTab={false}
            style={{ maxWidth: "161px" }}
        >
            Save the Date
        </ButtonLink>
      )}
      <footer>
        <h2>© 2025 Swiss Post Cybersecurity</h2>
        <a className="yellow-link" href="./coc.html">
          Code of conduct
        </a>
      </footer>
    </div>
  );
};

export default VideoSection;
