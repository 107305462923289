import DroppedPinIcon from "../components/DroppedPinIcon";
import FreeIcon from "../components/FreeIcon";
import Logo from "../components/Logo";
import ShieldElectricIcon from "../components/ShieldElectricIcon";
import Spec from "../components/Spec";
import EventCountdown from "../components/EventCountdown";
import EventDate from "../components/EventDate";
import Hero from "../components/Hero";
import Organizers from "../components/Organizers";
import AboutTheEvent from "../components/AboutTheEvent";
import EventFormat from "../components/EventFormat";
import ImageCarousel from "../components/ImageCarousel";
import VideoSection from "../sections/VideoSection";
import ButtonLink from "../components/ButtonLink";
import eventFormatPhoto from "../assets/spiderman.jpeg";
import post_logo from "../assets/post logo.svg"
import video_image from "../assets/novotel_paradiso.jpg"


/*
import PlanningSection from "../sections/PlanningSection";
import SpeakerSection from "../sections/SpeakerSection";
import Sponsors from "../components/Sponsors";
import infomaniak from "../assets/infomaniak.png";
import pwc_outlined_logo from "../assets/pwc_outlined_logo.png";
import microsoft_logo from "../assets/microsoft_logo.png";
import hkta_logo from "../assets/hkta_logo.png";

import sentinel_one from "../assets/sponsors/SentinelOne_Logo_white-purple.png";
import fortinet_logo from "../assets/sponsors/fortinet-logo.png";
import airlock_logo from "../assets/sponsors/airlock-logo.png";
import elastic_logo from "../assets/sponsors/elastic-logo-reverse.svg";
import karspersky_logo from "../assets/sponsors/karsperky-logo.png";
import vectra_logo from "../assets/sponsors/vectra-logo.png";
import cyberreason from "../assets/sponsors/cyberreason-logo.png";
import tenable from "../assets/sponsors/tenable-logo.png";
import splunk from "../assets/sponsors/splunk.png";
import threatray from "../assets/sponsors/threatray-logo.png";
import speaker_daniel_kuenzli from "../assets/speakers/daniel-kuenzli.png";
import speaker_thomas_cueni from "../assets/speakers/thomas-cueni.jpg";
import speaker_rene_bodmer from "../assets/speakers/rene-bodmer.png";
import speaker_luca_bertolotto_cipriani from "../assets/speakers/luca-bertolotto.png";
import speaker_fabian_gentinetta from "../assets/speakers/fabian_gentinetta.jpeg";
import speaker_martin_de_jongh from "../assets/speakers/MartinDJ_SentinelOne.png";
import speaker_eric_de_bressing from "../assets/speakers/eric-de-bressing.jpeg";
import speaker_patrick_buchholz from "../assets/speakers/patrick-buchholz.jpg";
import speaker_marcel_zumbuehl from "../assets/speakers/marcel-zumbuehl.png";
import speaker_detlev_narr from "../assets/speakers/detlev-narr.png";
import speaker_unknown from "../assets/speakers/unknown_person.jpg";
*/



const eventDate: Date = new Date(2025, 4, 20, 12, 45); // 20 may 2025

export default function LuganoEventTab() {
  return (
    <div className="container">
      <section>
        <div style={styles.logoContainer}>
          <Logo width="auto" height="102px" />
        </div>
        <EventDate date={eventDate} />
        <Hero
          title="Hack’Major"
          subtitle="Taking cybersecurity seriously, but not ourselves"
          titleStyle={styles.title}
          subtitleStyle={styles.subtitle}
        />
        <div className="specs-container">
          <Spec icon={<DroppedPinIcon />} title="Novotel Paradiso" />
          <Spec icon={<FreeIcon />} title="100% Free" />
          <Spec icon={<ShieldElectricIcon />} title="100% cybersecurity" />
        </div>
        <EventCountdown date={eventDate} />
      </section>
      <section>
        <Organizers
          organizers={[
            { src: post_logo, name: "Swiss Post Cybersecurity" }
          ]}
        />
      </section>
      <section>
          {/*href="https://infomaniak.events/fr-ch/conferences/genevhack-2024-conferences/6Cz7pH327KEA4KrcLqN7ol1va9un"*/}
        <ButtonLink
          href="#"
          newTab={false}
          style={{ maxWidth: "161px" }}
        >
          Save the Date
        </ButtonLink>
      </section>
      {/*
      <Sponsors
        style={{ marginTop: "120px" }}
        logos={[
          [tenable, karspersky_logo],
          [pwc_outlined_logo, microsoft_logo, splunk, vectra_logo, elastic_logo, infomaniak, threatray, sentinel_one],
          [fortinet_logo, airlock_logo, cyberreason],
        ]}
      />
    */}
      <section>
        <AboutTheEvent mapUrl="./palexpo-plan.pdf"/>
      </section>
      <section>
        <EventFormat imageSrc={eventFormatPhoto}/>
      </section>
      <section>
        {/*
        <PlanningSection
          subtitle=""
          planning={[
            {
              time: "12:45 - 13:15",
              title: "",
              description: "<h1>Registration & Welcome coffee</h1>",
              index: 0,
            },
            {
              time: "13:15 - 13:30",
              title: "Paul Such, Urs Rufer",
              description: "Info about terreActive and Hacknowledge",
              index: 1,
            },
            {
              time: "13:30 - 14:05",
              title: "",
              description: "<p class=\"title\">Daniel Künzli - Tenable</p>Demystifying Active Query in OT Networks.<br><br><p class=\"title\">Marcel Zumbühl, Angelo Mathis - Swiss Post, ISSS</p>How can I avoid a communication meltdown in the event of cyber incident?",
              index: 2,
            },
            {
              time: "14:05 - 14:40",
              title: "",
              description: "<p class=\"title\">Fabian Gentinetta - Vectra</p>Attacking Complexity in Hybrid Environments.<br><br><p class=\"title\">Eric de Bressing - Splunk</p>Reduce Investigation and Response Time with automated threat analysis",
              index: 3,
            },
            {
              time: "14:40 - 15:15",
              title: "",
              description: "<p class=\"title\">Luca Bertolotto Cipriani - Microsoft</p>Cybercrime & Ransomware ecosystem in Western Europe.<br><br><p class=\"title\"> Trust Valley, Marcel Zumbühl - Swiss Post</p>Panel Discussion",
              index: 4,
            },
            {
              time: "15:15 - 15:45",
              title: "",
              description: "<h1>Coffee break</h1>",
              index: 5,
            },
            {
              time: "15:45 - 16:20",
              title: "",
              description: "<p class=\"title\">Emmanuel Schmid - Hacknowledge</p>Echoes of the HARPON: Insider Strategies from Remote Incident Response.<br><br><p class=\"title\">Thomas Cueni - Tenable</p>Cloud Security: Why identity is the New Perimeter?",
              index: 6,
            },
            {
              time: "16:20 - 16:55",
              title: "",
              description: "<p class=\"title\">Sebastien Michel - Elastic</p>Elastic Attack Discovery<br><br><p class=\"title\">Gilles Peter - Kaspersky</p>How do I recognize early on that I am a target?",
              index: 7,
            },
            {
              time: "16:00 - 19:00",
              title: "Microsoft Security Immersion CTF: Into the Breach",
              description: "In this exciting experience, you'll become a cybersecurity analyst who helps solve and mitigate complex and real-world security threats. Leverage the power of Microsoft 365 Defender and Microsoft Sentinel products to detect sophisticated attacks that compromise the network and span multiple phases of the attack lifecycle.",
              index: 10,
            },
            {
              time: "16:55 - 17:30",
              title: "",
              description: "<p class=\"title\">Endre Bangerter - Threatray</p>Addressing EDR Evasion: Enhancing detection with deep code analysis<br><br><p class=\"title\">Martin de Jongh - Sentinel One</p>Purple AI, Your AI security analyst. Help Security teams detect earlier, respond faster and stay ahead of attacks.",
              index: 8,
            },
            {
              time: "17:30 - 18:00",
              title: "",
              description: "<p class=\"title\">François-Jérôme Daniel, Patrick Mkhael - Hacknowledge</p>Azure Under Threat: Unpacking Initial Access Attack Techniques<br><br><p class=\"title\">Neil Margolius - PwC</p> Securing the Future: Advanced Cyber Defense Strategies in the Era of IT/OT Convergence",
              index: 9,
            },
            {
              time: "18:00 - 20:00",
              title: "Networking and Apero",
              description: "Networking and Apero",
              index: 11,
            }
          ]}
        />
        */}
      </section>
      {<section>
        {/*
        <SpeakerSection
          title="Multiple speakers and experts will be joining us !"
          subtitle="Attendees will leave feeling more connected to a community of builders and to the future of the evolving world of cybersecurity"
          speakers={[
            {
              firstName: "Daniel",
              lastName: "Künzli",
              title: 
                "Senior Security Engineer OT, EMEA",
              description:
                "Using only passive monitoring to detect assets in OT networks has a poor calculation model and is limited in terms of effectiveness and timeliness. However, many organizations still believe that this is the only option as network scanning in OT networks is inherently riskier. We present how active scanning methods can be used safely and effectively in the OT environment. It will also provide some use cases of hybrid scanning scenarios and their benefits in reducing deployment costs and optimizing the time-to-value of the deployed solution.",
              image:
              speaker_daniel_kuenzli,
            },
            {
              firstName: "René",
              lastName: "Bodmer",
              title:
                "Head of B2B Switzerland & Austria",
              description:
                "DFI (Digital Footprint Intelligence) - thanks to dark and deep web intelligence, recognise at an early stage that an attack is imminent - e.g. because company access is offered for auction. Benefit: gain time to harden systems thanks to early warning. Time is of the essence in this area!",
              image:
                speaker_rene_bodmer,
            },
            {
              firstName: "Luca",
              lastName: "Bertolotto Cipriani",
              title: "Regional Cybersecurity Risk Program Manager EMEA & CEE",
              description:
                "In the ever-evolving landscape of cyber threats, Switzerland has witnessed a significant rise in cybercrime and ransomware attacks. This presentation delves into the intricate ecosystem of ransomware operations within the Swiss digital domain, exploring the dynamics between attackers, their methodologies, and the impact on businesses",
              image:
                speaker_luca_bertolotto_cipriani,
            },
            {
              firstName: "Fabian",
              lastName: "Gentinetta",
              title: "",
              description:
                "Identity is central to attacks today. Identity cuts across environments. Boundaries split signal which adds complexity and attackers love complexity.",
              image:
                speaker_fabian_gentinetta,
            },
            {
              firstName: "Eric",
              lastName: "de Bressing",
              title: "Senior Staff Solutions Engineer",
              description:
                "In a fast-evolving cyber threat landscape, quick analysis and response are crucial for organizational security. Security Operations Centers (SOCs) grapple with the challenge of swiftly addressing threats in an environment where attackers constantly devise new strategies. Threat analysis often involves manual review across various tools, capturing secondary artifacts, and synthesizing data for insights and corrective action. This presentation will demonstrate optimizing incident response through an integrated solution and novel approaches to reduce Mean Time to Respond (MTTR) early in threat analysis to enhance the SOC efficiency.",
              image:
                speaker_eric_de_bressing,
            },
            {
              firstName: "Thomas",
              lastName: "Cueni",
              title: 
                "Senior Security Engineer",
              description:
                "Tenable One is an exposure management platform that helps security leaders predict the consequences of a cyberattack by providing an understanding of the relationships between assets, exposures, permissions and threats along an attack path. The platform combines broad exposure coverage for IT, OT and IoT assets, cloud resources, containers, web apps and identity platforms with threat intelligence and data science from Tenable Research. With the Cloud Native Application Protection Platform (CNAPP) integrated into Tenable One, users can keep an eye on and secure all cloud assets in multi-cloud environments. From complete asset discovery and in-depth risk analysis to compliance, Tenable Cloud Security automates complex security workflows through meaningful visualization and step-by-step recommendations. With a comprehensive understanding of identities and infrastructure dependencies, the solution uncovers, prioritizes and remediates security gaps, helping to reduce your cloud attack surface and enforce the principle of least privilege at scale.",
              image:
                speaker_thomas_cueni,
            },
            {
              firstName: "Martin",
              lastName: "de Jongh",
              title: "Director GTM Engineering EMEA",
              description:
                "In this technical live demonstration, see how the world’s most advanced cybersecurity AI from SentinelOne can turn your organization’s data into its strongest defense. Scale autonomous protection across the enterprise with patent-pending AI technology. Simplify the Complex - Streamline investigations by intelligently combining common tools, synthesizing threat intelligence and contextual insights into a single conversational user experience. Uplevel Every Analyst - Find hidden risk, conduct deeper investigations, and respond faster—all in natural language. Train analysts with power query translations from natural language prompts. Accelerate SecOps - Advance your SecOps with our hunting quick starts, AI-powered analyses, auto-summaries, and suggested queries. Collaborate on investigations with shareable notebooks. Safeguard Your Data - Leverage a solution designed for data protection and privacy. Purple AI is never trained with customer data and is architected with the highest level of safeguards.",
              image:
              speaker_martin_de_jongh,
            },
            {
              firstName: "Detlev",
              lastName: "Narr",
              title: "Senior Consultant Threat Intelligence",
              description:
                "Threat Intelligence Services provide access to all information required to defend against cyber threats. They are provided by the world’s leading teams of researchers and analysts. There are a wide range of services that can be used for the respective use cases. From reporting and Darkweb monitoring to threat look-up and data feeds, there are many different possibilities. Threat intelligence provides a comprehensive overview of the global threat landscape by combining threat intelligence data, threat data feeds and proprietary investigations by vendors to support companies in the fight against cyber threats.",
              image:
                speaker_detlev_narr,
            },
            {
              firstName: "Patrick",
              lastName: "Buchholz",
              title: "Distribution & Channel Manager",
              description:
                "Generative AI, an emerging technology, enables machines to generate human-like content such as images, text and even human voices. The novelty here is not that AI-generated content has a special quality or would replace human experts, but that AI tools enable the incapable to become mediocre. This is precisely why this technology offers enormous mass potential for creative applications, but also for potential abuses.",
              image:
                speaker_patrick_buchholz,
            },
            {
              firstName: "Marcel",
              lastName: "Zumbühl",
              title: "CISO Post",
              description:
                "Current threat level, activities and actors. Crisis preparedness: how is Swiss Post preparing for an incident? What mitigating measures are in place at organizational and process level?",
              image:
                speaker_marcel_zumbuehl,
            },
            {
              firstName: "Gilles",
              lastName: "Peter",
              title: "Territory Manager Suisse",
              description:
                "",
              image:
                speaker_unknown,
            }
          ]}
        />
        */}
      </section>}
{       <ImageCarousel
        images={[
          eventFormatPhoto,
          "/img/21efdf712cf79fbd148856011f58bc6e.jpeg",
          "/img/2205cda10dfebc8d1ca3a5bbf2f8515e.jpeg",
          "/img/a7dc9838a6d79336be95edcbb10943c9.jpeg",
          "/img/c67f653b353385bcc7e424359aa6b3e8.jpeg",
          "/img/d6b45f79c4a62172598e9531d0587924.jpeg"
        ]}
        borderRadius="20px"
        height="284px"
        width="90%"
      /> }
      <section>
        <VideoSection
          video="https://vimeo.com/225843060"
          title="Hack'Major"
          registerUrl="https://infomaniak.events/fr-ch/conferences/genevhack-2024-conferences/6Cz7pH327KEA4KrcLqN7ol1va9un"
          image={video_image}
        />
      </section>
    </div>
  );
}

const styles = {
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    background: "linear-gradient(90deg, #FFF 27.78%, #E1E1E1 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Poppins",
    fontSize: `${window.outerWidth < 768 ? "12vw" : "5vw"}`,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    margin: 0,
  },
  subtitle: {
    fontFamily: "Arvo",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "-18px",
    textAlign: "center" as "center",
  },
};
